h3, label{
    font-weight: bold;
    color: #488A99;
}
#SubmitButton{
    background-color: none;
    color: #488A99;
    width: 120px;
    height: 40px;
    font-size: 20px;
    border: 1px solid #488A99;
    border-radius: 5px;
    font-weight: bold;
}
#SubmitButton:hover{
    background-color: #488A99;
    color: white;
    width: 120px;
    height: 40px;
    font-size: 20px;
    border: 1px solid white;
    border-radius: 5px;
    font-weight: bold;
}