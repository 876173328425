@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}
:root {
  --background-color: #488a99;
  --text-color: white;
}

body {
  background-color: #f4f7fe !important ;
  /* background-color:#D2A399 !important ; */
}
label {
  font-size: 16px !important;
}
#ShedPicture {
  width: 85%;
  height: 150%;
  margin-top: 5%;
  margin-bottom: 2%;
}
#Shed1 {
  cursor: pointer;
}
.curserPointer {
  cursor: pointer !important;
}
#LoginPageHeading {
  background-color: #488a99;
  color: white;
  padding: 20px;
}

.topBar .Page {
  font-size: 33px;
  font-weight: 700;
  color: #1b254b;
}
.topBar .BreadcrumbCustom .breadcrumb .breadcrumb-item {
  font-size: 14px;
  font-weight: 400;
  color: #1b254b !important;
}

.topBar {
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
.topBar .rightBlock {
  height: 4rem;
  width: 12.5%;
  background-color: white;
  border-radius: 50px;
  padding: 5px;
  box-shadow: 0 5px 10px rgba(37, 99, 233, 0.387);
  color: #a3aed0;
}

@media only screen and (min-width: 1440px) {
  .topBar .rightBlock {
    width: 10%;
    font-size: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .topBar .rightBlock {
    width: 100%;
    font-size: 20px;
    order: 1;
  }
}

.C-Border {
  border: 0.5px solid #488a99 !important;
}

.C-bg {
  background-color: #488a99 !important;
}

.C-color {
  color: #488a99 !important;
}

.C-color2 {
  color: #1b254b !important;
}

.C-color-white {
  color: #fff !important;
}

.C-hoverColor {
  &&:is(:hover) {
    color: #488a99 !important;
  }
}

.bg-white {
  background-color: #fff !important;
}
.C-shadow {
  /* box-shadow: 0px 5px 15px #488a9977; */
  box-shadow: 0 5px 10px #488a9977;
}

.breadcrumb {
  padding: 0 !important;
  margin: 0 !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.brm-hover,
.brm-hover a {
  text-decoration: none;
  &&:is(:hover) {
    color: #488a99 !important;
    cursor: pointer;
    text-decoration: underline;
  }
}

.ant-statistic-title {
  color: #488a99 !important;
}

.Card-hover {
  border: none;
}
.Card-hover:hover {
  border: 0.5px solid #488a99;
  background-color: #f4f7f8;
}
.rounded-start-15 {
  border-start-start-radius: 15px;
  border-end-start-radius: 15px;
}

.fs-14 {
  font-size: 16px;
}

.imageHover .deleteImg {
  display: none;
  background-color: #0000001f;
  width: 7rem;
  height: 7rem;
}
.imageHover:hover .deleteImg {
  display: flex;
}

.accordion-button {
  box-shadow: none !important;
  &&:hover {
    color: #fff !important;
    background-color: #488a99 !important;
  }
}

.accordion-button:hover::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #488a99 !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#components-dropdown-demo-arrow .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}

.ant-dropdown-menu {
  width: 10rem;
}
.ButtonPrimaryDisabled {
  background-color: #004757;
  color: #fff;

  font-size: 20px;
  border: 1px solid #004757;
  border-radius: 5px;

  font-weight: bold;
}
.ButtonPrimary {
  background-color: white;
  color: #488a99;

  font-size: 20px;
  border: 1px solid #488a99;
  border-radius: 5px;

  font-weight: bold;

  &&:hover {
    background-color: #488a99;
    color: white;
    font-size: 20px;

    border: 1px solid white;
    border-radius: 5px;

    font-weight: bold;
  }
}

.ButtonPrimaryReverse {
  background-color: #488a99;
  color: white;
  height: 40px;
  font-size: 20px;
  border: 1px solid #488a99;
  border-radius: 5px;

  font-weight: bold;

  &&:hover {
    background-color: white;
    color: #488a99;

    font-size: 20px;

    border: 1px solid #488a99;
    border-radius: 5px;

    font-weight: bold;
  }
}

.w-5 {
  width: 5%;
}

.form-control:is(:hover, :focus, :active) + .input-group-text {
  border-color: #488a99;
}
.form-control:is(:hover, :focus, :active) {
  border-color: #488a99;
}

.input-group:hover .form-control {
  border-color: #488a99 !important;
}

.input-group:hover .input-group-text {
  border-color: #488a99 !important;
}

.box-shadow-0 {
  box-shadow: none !important;
  &&:is(:hover, :focus, :active) {
    box-shadow: none !important;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.RegisterAddressBlock {
  margin-bottom: 7.6rem;
}

@media (max-width: 767px) {
  .RegisterAddressBlock {
    margin-bottom: 2rem;
  }
}

.noIcon .accordion-button::after {
  display: none !important;
}

.custom_accordion .accordion-button {
  border-radius: 0px !important;
}

.rounded-0 {
  border-radius: 0px !important;
  ::is(:hover, :focus, :active) {
    border-radius: 0px !important;
  }
}
:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper .ant-table {
  font-size: 16px !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  font-weight: 700 !important;
}
