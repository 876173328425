#ViewButton {
  width: 70px !important;
  height: 30px !important;
  background-color: transparent !important;
  border: none;
  /* border-bottom: 1px solid #488a99; */
  color: #488a99;
  font-size: 16px;
  position: absolute;
  bottom: 1rem;
}
#ViewButton:hover {
  width: 70px;
  height: 30px;
  background-color: #488a99 !important;
  /* border: 1px solid #488a99; */
  color: white;
  font-size: 16px;
}
