@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.sidebar {
  position: fixed;
  background-color: #ffff;
  color: black;
  top: 0;
  left: 0;
  width: 313px;
  height: 100vh;
  box-shadow: 0 0 10px rgba(37, 99, 233, 0.192);

  transform: translateX(-100%);

  transition: all 0.4s;
  z-index: 100;
}

.DMFamily {
  font-family: "DM Sans", sans-serif !important;
  font-optical-sizing: auto !important;
}

.sidebar--open {
  transform: translateX(0%);
}


.logoText h5 span {
  all: unset;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 25px;
}
.logoText h5 {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 800 !important;
  padding: 0px;
  margin: 0px;
}

.sidebar li:first-child {
  font-size: 25px !important;
  font-weight: 800;

  margin: 20px 0px 20px 0px;
  padding-bottom: 30px;

  border-bottom: 1px solid #c1c1c1;

  color: #488a99;

  &&:hover{
    background-color: white;
    color: #488a99;
  }
}

.sidebar li {
  list-style: none;
  font-size: 16px !important;
  font-family: "DM Sans", sans-serif !important;

  border-bottom: 1px solid #488a99;

  padding: 0.5rem 2rem;
  align-items: center;
  font-weight: 700;

  color: rgb(24, 24, 24);

}
.sidebar li:hover {
  background-color: #488a99;
  color: white;
  cursor: pointer;
}

.custom-scrollbar {
  
  overflow: auto; /* Hide default scrollbars */
  /* overflow-y: scroll; Enable vertical scrollbar */
  /* Custom scrollbar styles */

  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #264a52 #488a99; /* Firefox */

  &::-webkit-scrollbar {
    width: 10px; /* Width for Webkit browsers */
    height: 10px; /* Optional: Set height if needed (defaults to width) */
    background-color: #488a99;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #264a52;
    border-radius: 50px !important; /* Curved edges */
  }

  &::-webkit-scrollbar-track {
    background-color: #488a99;
  }

  &::-moz-scrollbar {
    width: 10px; /* Width for Firefox */
    height: 10px; /* Optional: Set height if needed (defaults to width) */
  }

  &::-moz-scrollbar-thumb {
    background-color: #264a52;
    border-radius: 50px !important; /* Curved edges for Firefox */
  }
}
