@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

*{
    
    font-family: 'Montserrat', sans-serif;
}
#MyShedsHeading{
    background-color: #488A99;
    color: white;
    padding: 20px;
}
.form{
    color: #488A99;
}
#Shed1{
    padding-top: 10px;
    padding-bottom: 10px;
}
#BackButton{
    background-color: white;
    color: #488A99;
    width: 120px;
    height: 40px;
    font-size: 20px;
    border: 1px solid #488A99;
    border-radius: 5px;
    font-weight: bold;
}
#BackButton:hover{
    background-color: #488A99;
    color: white;
    width: 120px;
    height: 40px;
    font-size: 20px;
    border: 1px solid white;
    border-radius: 5px;
    font-weight: bold;
}
#DeleteButton{
    background-color: white;
    color: red;
    width: 80px;
    height: 40px;
    font-size: 15px;
    border: 1px solid red;
    border-radius: 5px;
    font-weight: bold;   
    margin-right: 10px;
}
#DeleteButton:hover{
    background-color: red;
    color: white;
    width: 80px;
    height: 40px;
    font-size: 15px;
    border: 1px solid red;
    border-radius: 5px;
    font-weight: bold;   
    margin-right: 10px;   
}
#UpdateButton{
    background-color: white;
    color: #488A99;
    width: 80px;
    height: 40px;
    font-size: 15px;
    border: 1px solid #488A99;
    border-radius: 5px;
    font-weight: bold;   
    margin-right: 10px;
}
#UpdateButton:hover{
    background-color: #488A99;
    color: white;
    width: 80px;
    height: 40px;
    font-size: 15px;
    border: 1px solid #488A99;
    border-radius: 5px;
    font-weight: bold;   
    margin-right: 10px;   
}
#PrintButton{
    background-color: white;
    color: yellowgreen;
    width: 100px;
    height: 40px;
    font-size: 20px;
    border: 1px solid yellowgreen;
    border-radius: 5px;
    font-weight: bold;   
    margin-left: 10px;
}
#PrintButton:hover{
    background-color: yellowgreen;
    color: white;
    width: 100px;
    height: 40px;
    font-size: 20px;
    border: 1px solid yellowgreen;
    border-radius: 5px;
    font-weight: bold;   
    margin-left: 10px;
}

#DetailsButton{
    width: 200px;
    height: 30px;
    background-color: none;
    border: none;
    border-bottom: 1px solid #488A99;
    color: #488A99;
    font-size: 15px;
}
#DetailsButton:hover{
    width: 200px;
    height: 30px;
    background-color: #488A99;
    border: none;
    border-bottom: 1px solid #488A99;
    color: white;
    font-size: 15px;
}